// import * as cocoSsd from '@tensorflow-models/coco-ssd';
import * as cocoSsd from './airplane-detector';

import {
  getImagesToProcess,
  renderProcessedImagesOverOriginal,
  waitForImageToLoad,
} from './imageHelpers';

import { getGeojsonFromPrediction } from './geojson';

const DEFAULT_MODEL_NAME = 'defaultModel';

// ssd in tensorflowjs
// https://medium.com/@bourdakos1/tensorflow-js-real-time-object-detection-in-10-lines-of-code-baf15dfb95b2
// https://github.com/tensorflow/tfjs-models/blob/master/coco-ssd/src/index.ts

let cocoModel;

function buildModelURLS(modelString) {
  return {
    modelURL: `https://s3-us-west-2.amazonaws.com/airplane-detector-model/${modelString}/tensorflowjs_model.pb`,
    weightsURL: `https://s3-us-west-2.amazonaws.com/airplane-detector-model/${modelString}/weights_manifest.json`,
  };
}

export function loadModel(modelString = DEFAULT_MODEL_NAME) {
  return new Promise((accept, reject) => {
    console.time('loading model');

    const modelURLs = buildModelURLS(modelString);

    cocoSsd
      .load(modelURLs.modelURL, modelURLs.weightsURL)
      .then(model => {
        console.timeEnd('loading model');
        cocoModel = model;
        accept(model);
      })
      .catch(err => {
        console.timeEnd('loading model');
        reject(err);
      });
  });
}

// https://stackoverflow.com/questions/35612428/call-async-await-functions-in-parallel
// https://techbrij.com/javascript-async-await-parallel-sequence
export async function detectObjects() {
  console.log('\n\ndetect objects\n\n');

  // if no model has been loaded, wait here until its loaded
  if (!cocoModel) {
    await loadModel();
  }

  const imagesToProcess = getImagesToProcess();

  const processingPromises = [];

  // TODO: find way to async return geojson objects for everything rendered so far...
  if (imagesToProcess) {
    imagesToProcess.forEach(image => {
      const processingPromise = new Promise((accept, reject) => {
        const img = new Image(); // eslint-disable-line
        img.crossOrigin = 'anonymous';
        img.src = image.src;
        img.id = image.id;

        console.time(`predict-${img.id}`);

        return waitForImageToLoad(img).then(() => {
          return cocoModel.detect(img).then(predictions => {
            console.timeEnd(`predict-${img.id}`);

            if (predictions && predictions.length > 0) {
              const filteredPredictions = predictions.filter(prediction => prediction.score > 0.8);

              // TODO: filter by zoom layer

              if (filteredPredictions) {
                renderProcessedImagesOverOriginal(img, filteredPredictions);

                const geojsonObjects = [];

                filteredPredictions.forEach(prediction => {
                  console.log(img);
                  const geojson = getGeojsonFromPrediction(prediction, img.src);
                  console.log(geojson);
                  geojsonObjects.push(geojson);
                });

                // return Promise.resolve(geojsonObjects);
                // return geojsonObjects;
                return accept(geojsonObjects);
              }
            }
            return accept();
          });
        });
      });

      processingPromises.push(processingPromise);
    });

    console.log('%c processingPromise execution: ', 'color: red');
    console.log(processingPromises);
    return Promise.all(processingPromises);
  }
}
