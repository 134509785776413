import { point, multiPoint } from '@turf/helpers';
import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import { mul } from '@tensorflow/tfjs';

//
if (typeof(Number.prototype.toRad) === 'undefined') {
  Number.prototype.toRad = function() {
    return this * Math.PI / 180;
  }
}


export function createGeojsonBoundingBox(right, bottom, left, top) {
  console.log(arguments);
  // const northWest = point([left, top]);
  const northEast = point([top, right]);
  const southWest = point([bottom, left]);
  console.log(northEast);
  console.log(southWest);
  // const southEast = point([right, bottom]);

  const multiP = multiPoint([northEast, southWest]);
  console.log('multiP: ', multiP);
  const boundingBox = bbox(multiP);
  console.log(boundingBox);
  const bbpoly = bboxPolygon(boundingBox);

  console.log(bbpoly);

  return bbpoly;
}

export function bboxImageToCoordinates(right, bottom, left, top) {
  console.log('bboxImageToCoordinates');
}

export function getGeojsonFromPrediction(prediction, srcUrl) {
  console.log(prediction);
  const geojson = getLatLongFromTileURL(srcUrl);
  return geojson;
}

export function getTileURL(lat, lon, zoom) {
  console.log(arguments);
  const xtile = parseInt(Math.floor( (lon + 180) / 360 * (1<<zoom) ), 10);
  const ytile = parseInt(Math.floor( (1 - Math.log(Math.tan(lat.toRad()) + 1 / Math.cos(lat.toRad())) / Math.PI) / 2 * (1<<zoom) ), 10);
  console.log('xtile: ', xtile, ytile);
  return `${zoom}/${xtile}/${ytile}`;
}

export function getLatLongFromTileURL(url) {
  if (!url) return;
  let [zoom, ytile, xtile] = url.split('/').slice(-3);
  // trim extension off the end
  [xtile] = xtile.split('.').slice(0);
  console.log(zoom, xtile, ytile);
  console.log('xtile: ', xtile);

  // invert this
  // parseInt(Math.floor( (1 - Math.log(Math.tan(lat.toRad()) + 1 / Math.cos(lat.toRad())) / Math.PI) / 2 * (1<<zoom) ), 10);
  let lat = '';

  // lat is 37.619474

  // invert this
  // parseInt(Math.floor( (lon + 180) / 360 * (1<<zoom) ), 10)

  let bitwise = (1<<zoom);  // 131072, 2230.3800340328985
  // let bitwise = (1>>zoom);  // 0, 0
  // let bitwise = (zoom<<1);  // 34,  0.578586622547861
  // let bitwise = (zoom>>1);  // 8,  0.13613159387407828

  let numberator = 360 * bitwise;
  let denominator = Number(xtile) + 180;

  console.log('\n\n');
  console.log('bitwise: ', bitwise);
  console.log('numberator: ', numberator);
  console.log('denominator: ', denominator);

  let long = (numberator / denominator);
  console.log('long: ', long);

  // NE corner, SW corner
  return createGeojsonBoundingBox(-122.381348, 37.616582, -122.382807, 37.617534);
  // return [[37.617534, -122.381348],[37.616582, -122.382807]];
}
