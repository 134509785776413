import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ProcessingOverlay extends Component {
  componentWillMount() {
    console.log('loading map overlays');
  }

  render() {
    const { text, icon, spin } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          background: 'rgba(0,0,0,1)',
          marginTop: '30px',
          padding: '10px 25px',
          borderRadius: '30px',
          border: '#333 1px solid',
        }}
      >
        <div>
          {spin && <FontAwesomeIcon icon={icon} size="4x" spin />}
          {!spin && <FontAwesomeIcon icon={icon} size="4x" />}
        </div>
        <div style={{ paddingLeft: '15px', fontSize: '1.4em' }}>{text}</div>
      </div>
    );
  }
}

export default ProcessingOverlay;
