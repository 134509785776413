// draw bounding boxes on canvas context
export function drawBoundingBoxes(ctx, predictions) {
  if (!predictions) {
    return ctx;
  }

  predictions.forEach(prediction => {
    const [x, y, width, height] = prediction.bbox;
    ctx.strokeRect(x, y, width, height);
  });

  return ctx;
}

// draw text on canvas context
export function drawText(ctx, predictions) {
  if (!predictions) {
    return ctx;
  }

  predictions.forEach(prediction => {
    const [x, y, height] = [prediction.bbox[0], prediction.bbox[1], prediction.bbox[3]];
    // TODO: fix the positioning of text that doesn't fit
    const yPosition = y > 5 ? y - 5 : y + height + 5;
    const confidence = (prediction.score * 100).toFixed(2);
    const text = `${prediction.class}: ${confidence}%`;
    ctx.fillText(text, x - 2, yPosition);
  });

  return ctx;
}
