import React, { Component } from 'react';
import uuid from 'uuid';
import L from 'leaflet';

import { detectObjects, loadModel } from '../../utils/objectDetector';
import OverlaySpinner from '../overlaySpinner/OverlaySpinner';
import ProcessingOverlayContainer from '../processingOverlays/ProcessingOverlayContainer';
import Sidebar from '../sidebar/Sidebar';
import { getTileURL } from '../../utils/geojson';


/*
  TODO:
  - fix issue where zooming breaks detection
  - geojson support


*/

function keyFunction() {
  return uuid();
}

class MainMap extends Component {
  constructor(props) {
    super(props);

    this.handleDetectObjects = this.handleDetectObjects.bind(this)

    this.state = {
      lat: 37.617632,
      lng: -122.380789,
      zoom: 17,
      isModelLoading: true,
      modelError: false,
      isProcessing: false,
      processingError: false,
      tileSetURL: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png',
      attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
      geojson: [],
    };
  }

  componentDidMount() {
    this.setTileset(this.state.tileSetURL, this.state.attribution);

    this.map = L.map('map', {
      center: [this.state.lat, this.state.lng],
      zoom: this.state.zoom,
      layers: [this.tileLayer],
    });

    // handle when map movo ends
    this.map.on('moveend', () => {
      this.handleDetectObjects();
    });

    // get the image from the map
    // this.map.on('click', e => {
    //   console.log(getTileURL(e.latlng.lat, e.latlng.lng, this.map.getZoom()));
    // });

    loadModel()
      .then(() => {
        this.handleDetectObjects();
        this.setState({ isModelLoading: false });
      })
      .catch(() => {
        this.setState({ modelError: true });
      });
  }

  handleDetectObjects() {
    this.setState({ isProcessing: true }, () => {
      detectObjects()
        .then(geojson => {
          console.log(geojson);
          geojson = geojson.filter(item => item !== undefined);
          geojson = geojson.filter(item => item.length > 0);
          console.log(geojson);

          this.renderGeojsonToMap(geojson);

          const allGeojson = [...this.state.geojson].concat(geojson);
          console.log(allGeojson);

          this.setState({ geojson: allGeojson, isProcessing: false });
        })
        .catch(() => {
          this.setState({ processingError: true, isProcessing: false });
        });
    });
  }

  handleNewUrlSelect = (newURL, attribution) => {
    this.setState({ tileSetURL: newURL });
    this.setTileset(newURL, attribution);
  }

  renderGeojsonToMap(geojsonArray) {
    geojsonArray.forEach(item => {
      console.log(item);
      L.geoJSON(item[0]).addTo(this.map);
    });
  }

  setTileset = (url, attribution) => {
    this.tileLayer = L.tileLayer(url, {
      attribution: attribution
    });
  }

  render() {
    return (
      <div>
        <div id="map" />

        {this.state.isModelLoading && (
          <OverlaySpinner isLoading={!this.state.isModelLoading} error={this.state.modelError} />
        )}

        <ProcessingOverlayContainer
          isProcessing={this.state.isProcessing}
          processingError={this.state.processingError}
        />

        <Sidebar handleNewUrlSelect={this.handleNewUrlSelect} currentTilesetUrl={this.state.tileSetURL} currentAttribution={this.state.attribution} />
      </div>
    );
  }
}

export default MainMap;
