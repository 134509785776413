import React, { Component } from 'react';

import ProcessingOverlay from './ProcessingOverlay';

class ProcessingOverlayContainer extends Component {
  componentWillMount() {
    console.log('loading map overlays');
  }

  render() {
    return (
      <div
        style={{
          position: 'absolute',
          bottom: '30px',
          left: '30px',
          zIndex: 99999,
          color: 'white',
        }}
      >
        {this.props.processingError && <ProcessingOverlay text="Error" icon="exclamation-triangle" />}
        {this.props.isProcessing && <ProcessingOverlay text="Processing" icon="spinner" spin />}
      </div>
    );
  }
}

export default ProcessingOverlayContainer;
