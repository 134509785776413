import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSpinner,
  faExclamationTriangle,
  faBars,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';

import './App.css';

import MainMap from './components/mainmap/MainMap';
import NavBar from './components/navbar/navbar';

library.add(faSpinner);
library.add(faExclamationTriangle);
library.add(faBars);
library.add(faArrowLeft);
library.add(faArrowRight);

class App extends Component {
  render() {
    return (
      <div className="App">
        <NavBar />
        <MainMap />
      </div>
    );
  }
}

export default App;
