import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SidebarContent from './SidebarContent';

import './Sidebar.scss';

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleClick = (event) => {
    this.setState({open: !this.state.open});
  }

  render() {
    const { open } = this.state;
    return (
      <div>

        <div className={ open ? "map-sidebar-toggle map-sidebar-toggle-open" : "map-sidebar-toggle map-sidebar-toggle-closed"} onClick={this.handleClick}>
          <div className="toggle-arrow">
            {open ? (
              <FontAwesomeIcon icon="arrow-right" size="1x" />
            ) : (
              <FontAwesomeIcon icon="arrow-left" size="1x" />
            )}
          </div>
        </div>

        <div className={ open ? "map-sidebar map-sidebar-open" : "map-sidebar map-sidebar-closed"}>
            <div className="map-sidebar-body">
            {open ? (
              <div>
                <SidebarContent handleNewUrlSelect={this.props.handleNewUrlSelect} currentTilesetUrl={this.props.currentTilesetUrl} currentAttribution={this.props.currentAttribution} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
