import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// TODO: add progress bar to display loading percentage of model
// http://react-component.github.io/progress/examples/simple.html

// centering spinner horizontally and vertically
// https://www.kirupa.com/html5/centering_vertically_horizontally.htm

class OverlaySpinner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // loaded: 10,
    };
  }

  render() {
    const { error } = this.props;

    return (
      <div
        style={{
          position: 'absolute',
          zIndex: 10000,
          top: 56,
          width: '100%',
          height: 'calc(100vh - 56px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
      >
        <div
          style={{
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
          }}
        >
          {!error && (
            <div>
              <FontAwesomeIcon icon="spinner" size="6x" spin />
              <div
                style={{ fontWeight: 800, fontSize: '1.5em', letterSpacing: '2px', marginTop: 15 }}
              >
                Loading Model
              </div>
            </div>
          )}

          {error && (
            <div>
              <FontAwesomeIcon icon="exclamation-triangle" size="6x" />
              <div
                style={{ fontWeight: 800, fontSize: '1.5em', letterSpacing: '2px', marginTop: 15 }}
              >
                Error Loading Model
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default OverlaySpinner;
