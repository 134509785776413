import { drawBoundingBoxes, drawText } from './drawingHelpers';

function generateId() {
  return `${Math.random().toString(36).substr(2, 9)}-${Math.random().toString(36).substr(2, 9)}`; // eslint-disable-line
}

// accesses map, and returns all the images currently on the map.
// does not take any arguments
export function getAllTileImagesFromMap() {
    const el = document.querySelectorAll('.leaflet-tile-container'); // eslint-disable-line
    return [...el[0].children]; // eslint-disable-line
}

// pass in an array of images, and return only the new ones
export function getNewImagesFromArray(imgArray) {
  const newImages = [];

  imgArray.forEach(img => {
    // if this image has already NOT been processed,
    // add it the array that is returned and add it to the list of processed images
    if (!img.id) {
      img.id = generateId();  // eslint-disable-line
      newImages.push(img);
    }
  });
  return newImages;
}

// TODO: only return images that have NOT been processed yet
// call this function from wherever the images will be getting processed from
export function getImagesToProcess() {
  const allImages = getAllTileImagesFromMap();
  const imagesToProcess = getNewImagesFromArray(allImages);
  return imagesToProcess;
}

// takes the image, and returns a base64 string
export function drawBoundingBoxesOnImages(img, predictions) {
  // Create 2d context *without* canvas
  // https://stackoverflow.com/questions/3892010/create-2d-context-without-canvas
  // create canvas context to use for drawing the bounding boxes
  const canvas = document.createElement('canvas');  // eslint-disable-line
  canvas.width = 256;
  canvas.height = 256;
  const ctx = canvas.getContext('2d');

  // draw image to canvas
  ctx.drawImage(img, 0, 0);

  // set canvas styles
  ctx.strokeStyle = '#ff0000';
  ctx.fillStyle = '#ffffff';
  ctx.lineWidth = 4;

  // draw bounding boxes
  drawBoundingBoxes(ctx, predictions);

  // draw text
  ctx.font = '16px Arial';
  drawText(ctx, predictions);

  // save canvas as image
  const base64String = canvas.toDataURL();

  return base64String;
}

// this function takes an image, and draws it over where the original image was
export function renderProcessedImagesOverOriginal(img, predictions) {
  // set image src to base64
  // https://stackoverflow.com/questions/16449445/how-can-i-set-image-source-with-base64

  const imageBase64 = drawBoundingBoxesOnImages(img, predictions);

  // select the img element corresponding to this img, and replace to src with the base64 string
  const imageTag = document.getElementById(img.id); // eslint-disable-line
  // console.log(imageTag);
  // TODO: fix error here, imageTag is undefined after a zoom
  if (imageTag && imageTag.src) {
    imageTag.src = imageBase64;
  }
}

export function waitForImageToLoad(imageElement) {
  return new Promise(resolve => {
    imageElement.onload = resolve; // eslint-disable-line
  });
}
