import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import logo from '../../logo.png';

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  componentWillMount() {
    console.log('loading navbar');
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
      <div>
        <Navbar dark expand="md">
          <NavbarBrand href="/">
            <img src={logo} alt="globe icon" style={{ height: '20px', marginRight: '8px' }} />
            Detectable World
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {/*
              <NavItem>
                <NavLink href="/components/">About</NavLink>
              </NavItem>
              */}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>
                  <FontAwesomeIcon icon="bars" size="2x" />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem href="/query/">Query</DropdownItem>
                  <DropdownItem href="/about/">About</DropdownItem>
                  {/*
                  <DropdownItem divider />
                  <DropdownItem href="/profile/">Profile</DropdownItem>
                  */}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default NavBar;
