import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormGroup, InputGroup, InputGroupAddon, InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Label, Input, Button, Row, Col } from 'reactstrap';

import { loadModel } from '../../utils/objectDetector';

import './Sidebar.scss';

class SidebarContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      tilesetError: '',
      tilesetUrlInput: props.currentTilesetUrl,
      mapAttribution: props.currentAttribution,
      modelDropdownOpen: false,
      objectDetectorModelLabel: 'MobileNet - default',
    };
  }

  handleInputChange = (event) => {
    this.setState({ tilesetUrlInput: event.target.value, mapAttribution: '' })
  }

  handleSelect = (event) => {
    const selectedUrlValue = event.currentTarget.value;
    const attribution = event.currentTarget.attributes['attribution'].value;

    this.setState({ dropdownOpen: false, tilesetUrlInput: selectedUrlValue, mapAttribution: attribution });
  }

  handleSetUrl = (event) => {
    this.props.handleNewUrlSelect(this.state.tilesetUrlInput, this.state.mapAttribution);
  }

  handleModelSelect = (event) => {
    const objectDetectorModel = event.target.value;
    loadModel(objectDetectorModel);

    this.setState({
      modelDropdownOpen: !this.state.modelDropdownOpen,
      objectDetectorModelLabel: event.target.innerText,
      objectDetectorModel,
    });
  }

  render() {
    const { tilesetError, tilesetUrl } = this.state;

    return (
      <div>
        <FormGroup>
          <Row>
            <Col>
              <Label>Enter Custom Tileset Url</Label>
              <InputGroup>
                <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.dropdownOpen} toggle={() => (this.setState({ dropdownOpen: !this.state.dropdownOpen }))}>
                  <Button outline onClick={this.handleSetUrl}>Set URL</Button>
                  <DropdownToggle split outline />
                  <DropdownMenu>
                    <DropdownItem onClick={this.handleSelect}
                    value="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
                    attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community">
                      ESRI World Imagery
                    </DropdownItem>
                  </DropdownMenu>
                </InputGroupButtonDropdown>
                <Input
                  className="sidebar-input"
                  type="text"
                  value={this.state.tilesetUrlInput}
                  onChange={this.handleInputChange}
                  placeholder="Enter custom tileprovider URL"
                />
              </InputGroup>
            </Col>
          </Row>

          <Row style={{ marginTop: '30px' }}>
            <Col>
              <Label>Select Object Detector Model</Label>
              <Dropdown isOpen={this.state.modelDropdownOpen} toggle={() => {this.setState({ modelDropdownOpen: !this.state.modelDropdownOpen })}}>
                <DropdownToggle caret>
                  {this.state.objectDetectorModelLabel}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={this.handleModelSelect} value="defaultModel">MobileNet - default</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={this.handleModelSelect} value="mobilenet-1-22-2592">MobileNet - 2592</DropdownItem>
                  <DropdownItem onClick={this.handleModelSelect} value="mobilenet-1-22-3251">MobileNet - 3251</DropdownItem>
                  <DropdownItem onClick={this.handleModelSelect} value="mobilenet-1-22-3908">MobileNet - 3908</DropdownItem>
                  <DropdownItem onClick={this.handleModelSelect} value="mobilenet-1-22-4566">MobileNet - 4566</DropdownItem>
                  <DropdownItem onClick={this.handleModelSelect} value="mobilenet-1-22-5224">MobileNet - 5224</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>

        </FormGroup>
        <div>
          {tilesetError}
        </div>
      </div>
    );
  }
}

export default SidebarContent;
